<template>

	<div class="order">
		<h4>我的订单</h4>

		<div class="tab">
			<div :class="{'active':sign=='all'}" @click="getorderList('all',1)">全部订单({{userinfo.order_count_6}})</div>
			<div :class="{'active':sign==0}" @click="getorderList('0',1)">待付款({{userinfo.order_count_0}})</div>
			<div :class="{'active':sign==1}" @click="getorderList(1,1)">未出行({{userinfo.order_count_1}})</div>
			<div :class="{'active':sign==2}" @click="getorderList(2,1)">已出行({{userinfo.order_count_2}})</div>
			<div :class="{'active':sign==3}" @click="getorderList(3,1)">已取消({{userinfo.order_count_3}})</div>
			<div :class="{'active':sign==5}" @click="getorderList(5,1)">已退款({{userinfo.order_count_5}})</div>
			<!-- <div :class="{'active':sign==8}" @click="getorderList(8,1)">
				未评价({{pj8}})</div>-->
			<div class="bottom"></div> 
		</div>

		<div class="orderlist">
			<div class="orderitem" v-for="(item,i) in orderlist" :key="i">
				<div class="top flex-between">
					<p class="title">{{item.goods_name | ellipsis}}</p>
					<p>订单号: {{item.order_sn}}</p>
				</div>
				<div class="r1">
					<p>预定日期:  {{item.create_time}}</p>
					<p>出行日期:  {{item.start_time}}~{{item.end_time}}</p>
				</div>
				<div class="r1">
					<p>出行人数:{{item.adult_number}}成人 {{item.children_number}}儿童</p>
					<p>订单状态: {{item.order_status | stu}}</p>
				</div>
				<div class="r1">
					<p>总价(元): <span>{{item.goods_price}}</span></p>
					<p>实际支付(元): <span>{{item.order_price}}</span></p>
				</div>

				<div class="bottom">
					<p @click="tourl(item.id)">查看订单</p>
					<!-- <p v-if="item.order_status==0" @click="edit(item)">修改订单</p> -->
					<p class="active" v-if="item.order_status==0" @click="pay(item)">微信支付订单</p>
					<!-- <p class="active" v-if="item.order_status==0" @click="pay2(item)">支付支付宝订单</p> -->
					<p v-if="item.order_status==0" @click="del(item,1)">取消订单</p> 
					<p v-if="item.order_status==1 || item.order_status==4" @click="del(item,2)">我要退订</p>
					<!--未出行 4在退订中 -->
					<!-- <p v-if="item.is_evaluation==0" @click="showpj(item.order_sn)">立即评价</p> -->
					<!--已出行 is_evaluation 0未评价 1 已评价 -->
				</div>
			</div>
			
		</div>
			<el-pagination
				small
				layout="prev, pager, next"
				:total="total"
				@current-change="handleCurrentChange"
				:page-size="10"
				:hide-on-single-page="total==0"
				>
				</el-pagination>
	<!--  请使用微信扫描二维码-->
	<dia-pay ref="payCode" :id="id"></dia-pay> 

	<!-- 立即评价 -->
		<el-dialog :visible.sync="dia3"   title="评价" width="550px">
			<div class="fp">
				<p class="star">
					<span>满意度</span>
					<el-rate v-model="form2.leader_score" show-score text-color="#ff9900" >
					</el-rate>
				</p>
				<p class="star">
					
					<textarea placeholder="快来填写本次旅行的感受吧～" v-model="form2.content"></textarea>
				</p>
				<div>
					<el-upload
					:action='baseURL+"index.php/index/file/upload"'
					list-type="picture-card" 
					name="image"
					:before-upload="beforeAvatarUpload"
					:on-success="handleAvatarSuccess"
					:on-preview="handlePictureCardPreview"
					:on-remove="handleRemove">
					<i class="el-icon-plus"></i>
					  <div slot="tip" class="el-upload__tip">可多图上传</div>
					</el-upload>
					<el-dialog :visible.sync="dialogVisible">
					<img width="100%" :src="dialogImageUrl" alt="">
					
					</el-dialog>
				</div>
				
					<el-button type="warning" @click="upcomment">提交评价</el-button>
					
				
			</div>
		</el-dialog>
	</div>

</template>

<script>
import { orderListAPI,orderDel,orderCommentAPI,userinfoAPI} from "@/api/user";
import {releaseCouponAPI } from "@/api/signup"
import diaPay from '@/components/com/diaPay.vue'
import {baseURL} from "@/http/base"
export default {
components: {
    diaPay
  },
  data () {
    return {
		total:0,
		page:1,
      sign: "all",
	  orderlist:[],
	  dialogVisible:false,
	  dia3:false,
	  payinfo:{},
	  pay_status:0,
	  t:null,
	  order_sn:"",
	  dialogImageUrl:"",
	  fileList:[],
	   form2:{
		  order_sn:"",
		  content:"",
		  leader_score:5,
		  img:""
	  },
	  id:null,
	  userinfo:{},
	  pj8:0,
	  baseURL:baseURL
    }
  },
  created () {
	  if (this.$route.query.id) {
		   this.sign= this.$route.query.id
	  }
	 	
	  this.getorderList(this.sign)	 
  },
  
  filters:{
	  stu(s){
		// 0 待付款 1=未出行；2=已出行；3=已取消； 8 未评价
		switch(s){
			case 0:
				return "待付款"
			break;
			case 1:
				return "未出行"
			break;
			case 2:
				return "已出行"
			break;
			case 3:
				return "已取消"
			break;
			case 4:
				return "退订中"
			break;
			case 8:
				return "未评价"
			break;
		}

	},
	ellipsis: function(value) {
		      if (!value) return "";
		      if (value.length >23) {
		        return value.slice(0,22) + "...";
		      }
		      return value;
		    }
  },
  methods: {
	  pay2(item){
			if(item.is_audit==1){
				var data={
					order_sn:item.order_sn,
					return_url:process.env.VUE_APP_PC_URL+"#/user/orderInfo?id="+item.id
				}
				alipayFormAPI(data).then(res=>{
					console.log(res.pay_form)
					const div = document.createElement('formdiv');
					div.innerHTML = res.pay_form;
					document.body.appendChild(div);
					document.forms['alipaysubmit'].setAttribute('target', '_blank');
					document.forms['alipaysubmit'].submit();
					div.remove();
				})
			}else{
			this.$message.info(item.is_audit==2 ? "审核未通过" : "请等待审核")
			}
			
		},
	   pay(item){  
		   if(item.is_audit==1){
			
			let data={
				order_sn:item.order_sn,
				pay_amount:item.goods_price
			}
			this.id=item.id
			this.$refs.payCode.dialogVisible=true
            this.$nextTick(()=>{               
                this.$refs.payCode.paydata=data
                this.$refs.payCode.pay()
            })
		   }else{
			this.$message.info(item.is_audit==2 ? "审核未通过" : "请等待审核")
		   }		  
      },
	   getinfo(){
		  var _this=this
		  userinfoAPI().then(res=>{
			  _this.userinfo=res.shareInfo			 
		  })
		//   orderListAPI({order_status:8,page:1}).then(res=>{
		// 	 this.pj8=res.rowsCount
		// 	console.log(res)
		//   })
	  },
	 handleRemove(file, fileList) {
        console.log(file, fileList);
		var arr=[]
		
		for (let index = 0; index < fileList.length; index++) {
			arr.push(fileList[index].response.data.file_url)
			
		}
		this.fileList=arr
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
	  handleAvatarSuccess(res, file) {
        this.fileList.push(res.data.file_url) 
		console.log(res,file)
      },
      beforeAvatarUpload(file) {
        const isJPG = file.type === 'image/jpeg' ||
                       file.type === 'image/jpg' ||
                        file.type === 'image/png'
        const isLt2M = file.size / 1024 / 1024 < 5;

        if (!isJPG) {
          this.$message.error('上传头像图片只能是 jpeg/png/jpg 格式!');
          return false
        }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 5MB!');
           return false;
        }
       return true;
      },
	  showpj(order_sn){
		  this.dia3=true
		  this.form2.order_sn=order_sn
	  },
	upcomment(){
		this.form2.img=this.fileList.join(",")
		
		orderCommentAPI(this.form2).then(resa=>{
			this.dia3=false
			this.$message.success("评论已提交！")
		})
	},
    tourl (id) {
      this.$router.push({ path: '/user/orderInfo',query:{id} })
    },
	del(item,n){
		// if(item.order_status==4){
		// 	this.$message.success('正在审核中，请勿重复操作')
		// 	return
		// }
		// orderDel({id:item.id}).then(res=>{

		// 	this.$message.success(n==1 ? '取消成功' : '退订成功，请等待审核')
		// 	this.getorderList(this.sign)
		// })
		if(n==2){
			this.$message.success('退订请拨打：400-888-9318')
			return
		}
		if(item.order_status==4){
			this.$message.success('正在审核中，请勿重复操作')
			return
		}
		var tip=n==1 ? '取消成功' : '退订成功，请等待审核'
		this.$confirm('此操作将'+n==1 ? '取消' : '退订'+'该订单('+item.order_price+'元), 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
			orderDel({id:item.id}).then(res=>{
				this.$message.success(tip)
				this.getorderList(this.sign)
			})         
        }).catch(() => {
        //   this.$message({
        //     type: 'info',
        //     message: '撤销操作'
        //   });          
        });
		
	},
	edit(item){
		releaseCouponAPI({id:item.id}).then(res=>{
			this.$router.push({ path: '/signup',query:{id:item.price_id,oid:item.id} })
		})
	},
	getorderList(n,page){
		if(page==1){
			this.page=1
		}
		this.sign=n
		  var _this=this
		  
		  orderListAPI({order_status:n,page:this.page}).then(res=>{
			  _this.orderlist=res.cateList
			  this.total=res.rowsCount
		  })
		  this.getinfo()
	  },
	  handleCurrentChange: function(currentPage){
           this.page=currentPage
		   
            this.getorderList(this.sign)
       },
  }
}
</script>

<style lang="scss" scoped="scoped">
.paycode{        
	h2,p{
		text-align: center;
		line-height: 45px;
	}
}
	.order{
		padding: 5px 20px 20px;
		background: #ffffff;
		min-height: 500px;
		h4{
			font-size: 18px;
			font-weight: 500;
			color: #333333;
			font-weight: 500;
			line-height: 45px;
			text-indent: 10px;
		}

		.tab{
			display: flex;
			position: relative;
			div{
				cursor: pointer;
				width: 110px;
				height: 45px;
				font-size: 16px;
				font-weight: 500;
				line-height: 45px;
				text-align: center;
				color: #666666;
				border-bottom: 2px solid #cccccc;
				position: relative;
				z-index: 2;
			}
			.active{
				border-bottom: 2px solid #FF7C00;
				color: #ff7c00;
			}
			.bottom{
				width: 100%;
				height: 2px;
				border-bottom: 2px solid #cccccc;
				position: absolute;
				top: 43px;
				left: 0;
				z-index: 0;
			}

		}

		.orderlist{
			display: flex;
			flex-wrap: wrap;
			width: 980px;
			.orderitem{
				box-sizing: border-box;
				width: 480px;
				height: 165px;
				background: #f9f9f9;
				border-radius: 1px;
				padding: 10px;
				margin-top: 20px;
			}
			.orderitem:nth-child(odd){
				margin-right: 20px;
			}
			.top{
				margin-bottom: 10px;

				.title{
					width: 300px;
					overflow: hidden;
					text-overflow:ellipsis;
					white-space: nowrap;
					
					font-size: 14px;
					font-weight: 400;
					color: #333333;
					line-height: 20px;
				}
				p{
					font-size: 12px;
					color: #666;
				}
			}

			.r1{
				display: flex;
				p{
					flex: 1;
					line-height:28px;
					font-size: 12px;
					color: #666;
					span{
						color: #FF4C12;
						margin-left: 5px;
					}
				}
			}
			.bottom{
				border-top: 1px solid #e6e6e6;
				display: flex;
				justify-content: flex-end;
				padding-top: 5px;
				p{
					line-height:28px;
					font-size: 12px;
					color: #666;
					margin-left: 10px;
					cursor: pointer;
				}
				.active{
					color: #FF7C00;
				}
			}

		}
	}
.star{
	margin: 4px 0;
	display: flex;
	align-items: center;

	
	line-height: 50px;
	display: flex;
	align-items: center;
	>span{
		width:100px;
		font-size: 18px;
		line-height: 50px;
	}
	.el-rate__icon{
		font-size: 25px;
		
	}
	textarea{
		width: 457px;
		height: 120px;
		opacity: 1;
		background: #f6f7f9;
		border-radius: 4px;
		opacity: 1;
		font-size: 14px;
		text-align: left;
		color: #999999;
		line-height: 26px;
		padding: 10px;
	}
	
}
</style>
