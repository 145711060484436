<template>
    <div>
        <el-dialog title="请使用微信扫描二维码" :visible.sync="dialogVisible"  width="30%" @close="close">
            <div class="paycode">          
                <p> <img :src="payinfo.code_url" alt=""></p>
                <h2>¥{{payinfo.pay_money}}</h2>
            </div> 
        </el-dialog> 
    </div>
</template>

<script>
import {orderNowinfoAPI,wxpayAPI,orderStatusAPI } from "@/api/signup"
export default {
    props:["id"],
    data () {
    return {
        payinfo:{},
        t:null,
        pay_status:0,
        paydata:{
            order_sn:"",
            pay_amount:""
        },
        dialogVisible:false
    }
    },
    mounted () {       
        
    },
    methods: {
        ispay(){
            orderStatusAPI({order_sn:this.paydata.order_sn}).then(res=>{
                this.pay_status=res.pay_status
                if(this.pay_status==1 || this.pay_status==3){
                    this.$message.success("支付成功")
                    setTimeout(()=>{
                        this.dialogVisible=false
                        this.$router.push({ path: '/user/orderInfo',query:{id:this.id} })
                        
                    },1500)
                    this.close()                 
                }
            })
        },
        settime(){
            if(this.pay_status!=1 || this.pay_status!=3){
                this.t=setInterval(()=>{											
                    this.ispay()					
                },5000)
            }
        },
        pay(){
            wxpayAPI(this.paydata).then(res=>{					
                this.payinfo=res
                this.settime()
			})
        },
        close(){
            clearInterval(this.t)
        }
    }
}
</script>
        
<style lang="scss" scoped="scoped">
.paycode{
    
    h2,p{
        text-align: center;
        line-height: 45px;
    }
}
</style>